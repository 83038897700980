export enum ApiUrl {

    LOGIN = '/user/login',
    REFRESH = '/user/refresh',
    LOGOUT = '/user/logout',
    POST_FORGOT = '/user/forgot-pwd',
    POST_RESET = '/user/reset-pwd',

    POST_USER_SEARCH = "/user/search",
    GET_USER = "/user/one/:id",
    POST_ADD_USER = "/user/one",
    PUT_EDIT_USER = "/user/one/:id",
    DELETE_USER = "/user/one/:id",
    UNLOGG_USER = "/user/unlogg/:id",

    POST_TERMINAL_SEARCH = "/terminal/search",
    GET_TERMINAL = "/terminal/one/:id",
    POST_ADD_TERMINAL = "/terminal/one",
    PUT_EDIT_TERMINAL = "/terminal/one/:id",
    DELETE_TERMINAL = "/terminal/one/:id",
    POST_ASSOCIATE_TERMINAL_TO_STORE = "/terminal/store-association/:id",
    GET_DISSOCIATE_TERMINAL_FROM_STORE = "/terminal/store-dissociation/:id",
    POST_ASSOCIATE_TERMINAL_TO_USER = "/terminal/user-association/:id",
    GET_DISSOCIATE_TERMINAL_FROM_USER = "/terminal/user-dissociation/:id",
    GET_RESET_TERMINAL_SYNC_DATE = "/terminal/reset-sync-date/:id",

    POST_SIGN_SEARCH = "/sign/search",
    GET_SIGN = "/sign/one/:id",
    GET_ALL_SIGN = "/sign/all",
    POST_ADD_SIGN = "/sign/one",
    PUT_EDIT_SIGN = "/sign/one/:id",
    DELETE_SIGN = "/sign/one/:id",

    POST_STORE_SEARCH = "/store/search",
    GET_STORE = "/store/one/:id",
    POST_ADD_STORE = "/store/one",
    PUT_EDIT_STORE = "/store/one/:id",
    DELETE_STORE = "/store/one/:id",

    GET_SETTINGS = "/settings/one/:id",
    GET_DEFAULT_SETTINGS = "/settings/default",
    POST_ADD_SETTINGS = "/settings/one",
    POST_ADD_DEFAULT_SETTINGS = "/settings/default",
    PUT_EDIT_SETTINGS = "/settings/one/:id",
    PUT_EDIT_DEFAULT_SETTINGS = "/settings/default/:id",
    DELETE_SETTINGS = "/settings/one/:id",
    GET_SETTINGS_VALIDITY = "/settings/is-valid/:id",

    POST_CARTRIDGE_SEARCH = "/cartridge/search",
    GET_CARTRIDGE = "/cartridge/one/:id",
    POST_ADD_CARTRIDGE = "/cartridge/one",
    PUT_EDIT_CARTRIDGE = "/cartridge/one/:id",
    DELETE_CARTRIDGE = "/cartridge/one/:id",
    DOWNLOAD_CARTRIDGE_LIST = "/cartridge/download/:token",
    GET_DOWNLOAD_CARTRIDGE_TOKEN = "/cartridge/download-token",

    GET_STORE_EXPEDITIONS = "/expedition/of-store/:id",
    GET_EXPEDITION = "/expedition/one/:id",
    GET_RECEIVED = "/expedition/received/:id",

    POST_CASINO_RESPONSE_SEARCH = "/casino-response/search",
    GET_CASINO_RESPONSE_DOWNLOAD_TOKEN = "/casino-response/download-token",
    DOWNLOAD_CASINO_RESPONSE_LIST = "/casino-response/download/:token",

    GET_DASHBOARD_METRICS = "/dashboard/metrics",
    POST_DASHBOARD_SEARCH = "/dashboard/search",
}
